<template>
		<el-card>
			<advanced-search-bar>
				<template>
					<form-item-sites :abbr="true" :multiple="false" :local="true" v-model="queryForm.website" :parent="false" @onload="q.website=queryForm.website;searchEvent()" :clearable="false"></form-item-sites>
					<form-item label="选择日期:" style="width:300px;">
						<el-date-picker v-model="queryForm.date" type="daterange" value-format="yyyy-MM-dd" size="mini" :clearable="false">
						</el-date-picker>
					</form-item>
					<form-item>
						<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">查 询</el-button>
					</form-item>
				</template>
				<template #advanced>
					<form-item label="Account ID:">
						<el-input v-model.trim="queryForm.accountId" size="mini"></el-input>
					</form-item>
					<form-item label="Campaign ID:">
						<el-input v-model.trim="queryForm.campaignId" size="mini"></el-input>
					</form-item>
					<form-item label="操作:">
						<el-select v-model="queryForm.operation" clearable size="mini">
							<el-option v-for="item in operators" :label="item.label" :value="item.value" :key="item.value"></el-option>
						</el-select>
					</form-item>
					<form-item label="操作级别:">
						<el-select v-model="queryForm.level" clearable size="mini">
							<el-option v-for="item in operatorLevels" :label="item.label" :value="item.value" :key="item.value"></el-option>
						</el-select>
					</form-item>
				</template>
			</advanced-search-bar>
			<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);"></div>
			<el-table height="auto" :data="tableData" size="mini">
				<el-table-column label="站点" prop="website" min-width="100">
				</el-table-column>
				<el-table-column label="Account ID" prop="accountId" min-width="150">
				</el-table-column>
				<el-table-column label="Account Name" prop="accountName" min-width="150">
				</el-table-column>
				<el-table-column label="Campaign ID" prop="campaignId" min-width="150">
				</el-table-column>
				<el-table-column label="Campaign Name" prop="campaignName" min-width="150">
				</el-table-column>
				<el-table-column label="操作" prop="operator" min-width="100">
					<template slot-scope="scope">
						{{scope.row.operation|getLabel(operators)}}
					</template>
				</el-table-column>
				<el-table-column label="操作级别" prop="level" min-width="100">
				</el-table-column>
				<el-table-column label="操作ID" prop="operator.id" min-width="100">
				</el-table-column>
				<el-table-column label="操作ID Name" prop="operator.name" min-width="100">
				</el-table-column>
				<el-table-column label="操作原因" min-width="200">
					<template slot-scope="scope">
						{{scope.row | ruleCondition}}
					</template>	
				</el-table-column>
				<el-table-column label="操作时间" min-width="150">
					<template slot-scope="scope">
						{{scope.row.createdAt|formatDate}}
					</template>
				</el-table-column>
			</el-table>
			<el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
				:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
			</el-pagination>
		</el-card>
</template>

<script>
	import Moment from 'moment'
	import Common from '@/common/mixins/common.js'
	import {mapGetters} from 'vuex'
	import Page from '@/common/mixins/page.js'
	import Config from '../mixins/index.js'
	export default {
		mixins: [Common, Page, Config],
		data() {
			return {
				queryForm: {
					website: '',
					accountId: '',
					campaignId: '',
					operation: '',
					level: '',
					date: null
				},
				q: {},
				tableData: [],
				namespace: 'budget-switch',
			}
		},
		created() {
			this.queryForm.date = [
				Moment().add(-6, 'day').format('YYYY-MM-DD'),
				Moment().format('YYYY-MM-DD')
			]
		},
		computed: {
			...mapGetters({
				operators: 'budget-switch/operators',
				operatorLevels: 'budget-switch/operatorLevels'
			})
		},
		methods: {
			// Minix
			async getListUrl() {
				const trigger = this.trigger
				const payload = {
					params: {
						...this.q,
						...this.page
					},
					trigger
				}
				return this.$store.dispatch(`${this.namespace}/getList`, payload)
			},
			handleList(content) {
				this.tableData = content.map(v => {
					var ruleCondition = []
					try {ruleCondition = JSON.parse(v.ruleBody) || []} catch(e) {}
					console.log(ruleCondition)
					return {
						...v,
						ruleCondition
					}
				})
				console.log(this.tableData)
			},
			searchEvent() {
				this.q = {
					...this.queryForm,
					createdAtMaxStr: this.queryForm.date[1],
					createdAtMinStr: this.queryForm.date[0]
				}
				this.getList()
			}
		}
	}
</script>
<style lang="scss">
	
</style>

